import styles from './styles/home.module.css';
import Screen1 from '../../assets/images/png/Screen-1.png';
import Screen2 from '../../assets/images/png/Screen-2.png';
import {ReactComponent as GoogleStore} from '../../assets/images/svg/google-play-badge-logo-svgrepo-com.svg';
import {ReactComponent as AppStore} from '../../assets/images/svg/Download_on_the_App_Store_Badge.svg';
import Button from "../../components/button/Button";
import {useNavigate} from "react-router-dom";
import RoundedRectangle from "../../components/RoundedRectangle/RoundedRectangle";
import {features} from "./dict/features";
import {partnersImgPaths} from "./dict/partners";
import BasePage from "../base-page/BasePage";
import {t} from "i18next";
import {AppStoreButton, PlayStoreButton} from "../../components/StoreButtons/StoreButtons";

const Home = () => {
    const navigate = useNavigate();

    return (
        <BasePage>
            <div className={styles['home']}>
                <section className={styles['section1']}>
                    <div className={styles['section1-branding-container']}>
                        <div className={styles['section-1-txt-container']}>
                            <h1 className={styles['title']}>{t('landing.title')}</h1>
                            <p className={styles['description']}>{t('landing.description_1')}
                                <b> {t('landing.description_2')}</b></p>
                        </div>
                        <div>
                            <div className={styles['qr-container']}>
                                <img src={require('../../assets/images/jpg/qr.jpg')}
                                     style={{width: '200px', borderRadius: '6px'}} alt={'qr'}/>
                                <div className={styles['section1-store-buttons']}>
                                    <h2 style={{color: 'white', lineHeight: '1.5rem'}}>{t('home.scanQR')}</h2>
                                    <PlayStoreButton/>
                                    <AppStoreButton/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles['section1-branding-image']}>
                        <img className={styles['image']} src={Screen1} alt={'Screen1'}/>
                    </div>
                </section>
                <section className={styles['section3']}>
                    <div style={{flex: "1 1 50%"}}>
                        <img style={{width: '100%'}} className={styles['image']} src={Screen2} alt={"screen 2"}/>
                    </div>
                    <div className={styles['section3-txt-container']}>
                        <h1 className={styles['section2-title']}>{t('home.filesSection')}</h1>
                        <p className={styles['section3-description']}>{t('home.filesSection2')}</p>
                        <Button onPress={() => navigate('/AccidentFiles')}
                                className={`${styles['vehicle-b-btn']} ${styles['view-accidents-btn']}`}
                                color={'rgba(98, 141, 205, 1)'}>
                            <p className={styles['vehicle-b-btn-txt']}>{t('home.filesButton')}</p>
                            <i style={{color: 'white', fontSize: '3em'}} className="material-icons">east</i>
                        </Button>
                    </div>
                </section>
                <section className={styles['section4']}>
                    <h1 className={`${styles['section4-title']}`}>{t('_.features')}</h1>
                    <div className={styles['section4-features']}>
                        {features.map(feature => (
                            <RoundedRectangle key={feature.title} title={t(feature.title)}
                                              description={t(feature.description)} icon={feature.icon}/>
                        ))}
                    </div>
                </section>
                <section className={`${styles['section5']} ${styles['section5-margin']}`}>
                    <h1 className={`${styles['section4-title']}`}>{t('home.partners')}</h1>
                    <div className={styles['partners-container']}>
                        {partnersImgPaths.map((partnerSrc, idx) => (
                            <img key={idx} src={partnerSrc} alt={"partner"}/>
                        ))}
                    </div>
                </section>
            </div>
        </BasePage>
    )
}

export default Home;
