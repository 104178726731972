import formStyles from "../../../../styles/form.module.css";
import PhoneInput from "react-phone-number-input";
import {t} from "i18next";

const IdentificationFormSection = ({onSelectChange, input, setInput, channelChoice}) => (
    <div className={formStyles['form-container']}>
        <h4 className={formStyles['label']}>{t('files.phoneEmail')}</h4>
        <select name="channel"
                className={formStyles['codeInput']}
                onChange={onSelectChange}
                required
        >
            <option value="email">Email</option>
            <option value="mobile">{t('_.phone')}</option>
        </select>

        {channelChoice === 'email' &&
            <>
                <h4 className={formStyles['label']} style={{marginTop: '2rem'}}>{"Email"}</h4>
                <input placeholder={"example@gmail.com"}
                       className={formStyles['codeInput']}
                       type="email"
                       value={input} onChange={(e) => setInput(e.target.value)}
                       required
                />
            </>
        }

        {channelChoice === 'mobile' &&
            <>
                <h4 className={formStyles['label']} style={{marginTop: '2rem'}}>{t('_.phoneNumber')}</h4>
                <PhoneInput
                    defaultCountry="RO"
                    value={input}
                    onChange={setInput}
                    international={true}
                />
            </>
        }
    </div>
)

export default IdentificationFormSection;
