import BasePage from "../base-page/BasePage";
import Panel from "./components/Panel";
import {steps} from "./dict/steps";
import {t} from "i18next";
import tutorialStyles from './styles/tutorial.module.css';

const Tutorial = () => {
    return (
        <BasePage>
            <div className={tutorialStyles['video-container']}>
                <h1 className={tutorialStyles['video-title']}>{t('tutorial.video')}</h1>
                <iframe src="https://www.youtube.com/embed/vCSG6U728QY"
                        className={tutorialStyles['video']}
                        title="Tutorial Amiabila"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>

            {steps.map((step, idx) => (
                <Panel title={t(step.title)}
                       description={t(step.description)}
                       image={step.image}
                       direction={step.direction}
                       step={idx+1}
                       key={idx}
                />
            ))}
        </BasePage>
    )
}

export default Tutorial;
