import styles from './colorediconrectangle.module.css';

const ColoredIconRectangle = ({children, backgroundColor, style}) => {
    return (
        <div style={{...style, backgroundColor: backgroundColor}} className={styles['container']}>
            {children}
        </div>
    )
}

export default ColoredIconRectangle;
