export const forms = {
    email: {
        inputText: 'Email',
        buttonText: 'deleteUser.sendCode',
        buttonColor: 'rgba(73, 142, 245, 1)',
    },
    confirmDelete: {
        inputText: 'Confirm',
        buttonText: 'deleteUser.confirmDelete',
        buttonColor: 'rgba(73, 142, 245, 1)',
    }
}
