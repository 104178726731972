import Panel from "./components/Panel";
import {membriilist} from "./dict/membriilist";
import styles from './components/panels.module.css';
import {t} from "i18next";

const Membrii = () => {
    return (
        <div>
            <h1 className={styles['header']}>{t('membrii.header')}</h1>
            {membriilist.map((member, idx) => (
                <Panel title={t(member.title)}
                       description={t(member.description)}
                       image={member.image}
                       direction={member.direction}
                       phoneNr={t(member.phoneNr)}
                       email={t(member.email)}
                       fax={t(member.fax)}
                       web={t(member.web)}
                       linkAvizare={t(member.linkAvizare)}
                       member={idx+1}
                       key={idx}
                />
            ))}
        </div>
    )
}

export default Membrii;
