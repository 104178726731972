import styles from './accidentitem.module.css';
import {ReactComponent as AccidentSvg} from "../../../../assets/images/svg/Accident.svg";
import {t} from "i18next";

const AccidentItem = ({accident, selectAccident}) => {
    return (
        <div className={styles['item-container']} onClick={() => selectAccident(accident.accidentID)}>
            <AccidentSvg className={styles['icon']}/>
            <h3 className={styles['title']}>{t('_.accident')}</h3>
            <h4>{new Date(accident.accidentDateUTC).toLocaleDateString('ro-RO', {})}</h4>
        </div>
    )
}

export default AccidentItem;
