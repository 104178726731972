import BasePage from "../base-page/BasePage";
import styles from './styles/accidentfiles.module.css';
import AccidentFilesForm from "./components/AccidentFilesForm/AccidentFilesForm";
import {useEffect, useState} from "react";
import get_accidents_by_token from "../../helpers/api/get_accidents_by_token";
import {cleanAccidents} from "../../helpers/cleanAccidents";
import {useSearchParams} from "react-router-dom";
import StatusDiv from "./components/AccidentFilesForm/StatusDiv";
import {t} from "i18next";
import {setSessionTimeout} from "./helpers/setSessionTimeout";
import AccidentAndFilesList from "./components/AccidentAndFilesList/AccidentAndFilesList";

const AccidentFiles = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [accidentList, setAccidentList] = useState();
    const [error, setError] = useState();
    const [mode, setMode] = useState("form"); //form or list
    const [sessionTimerCount, setSessionTimerCount] = useState();

    const getEmailToken = () => {
        const token = searchParams.get("token");
        if (token) {
            sessionStorage.setItem("filesToken", token);
            searchParams.delete("token");
            setSearchParams(searchParams);
        }
    }

    useEffect(() => {
        const getAccidents = async () => {
            try {
                getEmailToken();
                const filesToken = sessionStorage.getItem("filesToken");
                if (filesToken) {
                    setSessionTimeout(setSessionTimerCount, setMode);
                    const accidents = cleanAccidents(JSON.parse(await get_accidents_by_token(filesToken)));
                    setAccidentList(accidents);
                    setMode('list');
                }
            } catch (e) {
                setError(e);
            }
        }
        void getAccidents();
    }, []);

    const revokeSession = () => {
        sessionStorage.removeItem('filesToken');
        setMode("form");
    }

    return (
        <BasePage>
            <div className={styles['container']}>
                <div>
                    <h1>{t('files.title')}</h1>
                    <p>{t('files.description')}</p>
                </div>
                {error &&
                    <div style={{marginTop: '1.5rem'}}>
                        <StatusDiv dismiss={() => setError()}
                                   statusObject={{
                                       icon: 'report',
                                       rectangleColor: 'red',
                                       text: error.message,
                                   }}/>
                    </div>
                }

                {mode === 'list' ?
                    <AccidentAndFilesList sessionTimerCount={sessionTimerCount}
                                          accidentList={accidentList}
                                          revokeSession={revokeSession}
                                          setError={setError}
                    />
                    :
                    <AccidentFilesForm setAccidentList={setAccidentList}
                                       setSessionTimerCount={setSessionTimerCount}
                                       setMode={setMode}
                                       setError={setError}
                    />
                }

            </div>
        </BasePage>
    )
}

export default AccidentFiles;
