import {BASE_URL} from "./base_url";
import custom_fetch from "../custom_fetch";

const reset_password = (newPassword, token) => {
    const url = `${BASE_URL}/Auth/Reset`;
    return custom_fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            newPassword,
            token: atob(token)
        })
    }).then(data => {
        return data;
    }).catch(error => {
        throw error;
    })
}

export default reset_password;
