import styles from '../AccidentList/accidentlist.module.css';
import accidentFilesStyles from '../../styles/accidentfiles.module.css';
import ImageItem from "./ImageItem";
import DocumentItem from "./DocumentItem";
import BackButton from "../../../../components/BackButton/BackButton";
import FullScreenImageModal from "../FullScreenImageModal/FullScreenImageModal";
import {useEffect, useState} from "react";

const FilesList = ({selectedAccident, goBack}) => {
    const {title, files} = selectedAccident;
    const [modalURL, setModalURL] = useState();

    useEffect(() => {
        if (modalURL) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'initial';
        }
    }, [modalURL])

    return (
        <>
            {modalURL && <FullScreenImageModal imgURL={modalURL} closeModal={() => setModalURL()}/>}
            <div>
                <div className={accidentFilesStyles['header']}>
                    <BackButton goBack={goBack}/>
                    <h2 className={accidentFilesStyles['title']}>{title}</h2>
                </div>
                <div className={accidentFilesStyles['accident-list']}>
                    <div className={styles['list-container']}>
                        {files.map(file => (
                            <div className={styles['item-flex']}>
                                {file.fileType === 'Form1' || file.fileType === 'Form2' ?
                                    <DocumentItem key={file.fileID} fileID={file.fileID}/>
                                    :
                                    <ImageItem key={file.fileID} fileID={file.fileID} openModal={setModalURL}/>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default FilesList;
