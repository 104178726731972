import i18n from "i18next";
import styles from './chooselanguage.module.css';

const ChooseLanguage = ({style}) => {
    const changeLanguage = (lang) => {
        localStorage.setItem("i18nextLng", lang);
        i18n.changeLanguage(lang);
    }

    return (
        <select onChange={e => changeLanguage(e.target.value)}
                value={localStorage.getItem("i18nextLng") || 'ro'}
                className={styles['select-language']}
                style={style}
        >
            <option key="ro" value="ro" onChange={() => changeLanguage('ro')}>{"RO"}</option>
            <option key="en" value="en" onChange={() => changeLanguage('en')}>{"EN"}</option>
        </select>
    )
}

export default ChooseLanguage;
