export const partnersImgPaths = [
    require('../../../assets/images/png/partners/logo_allianz.png'),
    require('../../../assets/images/png/partners/logo_asirom.png'),
    require('../../../assets/images/png/partners/logo_axeria.png'),
    require('../../../assets/images/png/partners/logo_dallbogg.png'),
    require('../../../assets/images/png/partners/logo_generali.png'),
    require('../../../assets/images/png/partners/logo_grawe.png'),
    require('../../../assets/images/png/partners/Logo-Groupama.png'),
    require('../../../assets/images/png/partners/logo_hellas.png'),
    require('../../../assets/images/png/partners/logo_omniasig.png'),
    require('../../../assets/images/png/partners/logo_eazy.png'),
    require('../../../assets/images/png/partners/baar_logo 1.png'),
  
]
