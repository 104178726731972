import custom_fetch from "../custom_fetch";
import {BASE_URL} from "./base_url";

const send_web_token_by_email = (email) => {
    const url = `${BASE_URL}/Accidents/SendWebTokenByEmail?Email=${encodeURIComponent(email)}`;
    return custom_fetch(url, {
        method: 'POST'
    }).then(data => {
        return data;
    }).catch(error => {
        throw error;
    })
}

export default send_web_token_by_email;
