import {t} from "i18next";
import {NavLink} from "react-router-dom";

const Links = ({links, linkClass}) => (
    links.map((link, idx) => {
        if (link.absoluteTo) {
            return <a key={link.title} className={linkClass} href={link.absoluteTo}>{t(link.title)}</a>
        }
        return (
            <NavLink style={({isActive}) => isActive ? {color: 'rgba(66, 129, 225, 1)'} : {}}
                     className={linkClass}
                     to={link.to}
                     key={idx}
            >
                {t(link.title)}
            </NavLink>
        )
    })
)

export default Links;
