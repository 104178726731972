import ColoredIconRectangle from "../../../../components/colored-icon-rectangle/ColoredIconRectangle";

const StatusDiv = ({statusObject, dismiss = null}) => (
    <div style={{display: 'flex', alignItems: 'center', gap: '.7rem', wordBreak: 'break-all'}}>
        {dismiss && <span onClick={dismiss} style={{fontSize: '1.5rem', cursor: "pointer"}}>&times;</span>}
        <ColoredIconRectangle style={{padding: '.1rem'}} backgroundColor={statusObject.rectangleColor}>
            <i style={{color: 'white', fontSize: '2.5rem'}} className="material-icons">{statusObject.icon}</i>
        </ColoredIconRectangle>
        <p>{statusObject.text}</p>
    </div>
)

export default StatusDiv;
