import BasePage from "../base-page/BasePage";
import {ReactComponent as CircleTickTransparent} from '../../assets/images/svg/CircleTickTransparent.svg';
import styles from '../verify-email-token-b/styles/verifyemailtokenb.module.css';
import {useParams} from "react-router-dom";
import {useState} from "react";
import {t} from "i18next";
import ResetPasswordForm from "./components/ResetPasswordForm";
import StatusDiv from "../accidents-files/components/AccidentFilesForm/StatusDiv";

const ResetPassword = () => {
    const [feedback, setFeedback] = useState();
    const {resetToken} = useParams();

    return (
        <BasePage>
            {feedback === 'success' ?
                <div className={`flex-container flex-grow ${styles.successContainer}`}>
                    <CircleTickTransparent className={styles.successIcon}/>
                    <h1 className={styles.successTitle}>{t('_.congrats')}</h1>
                    <p className={styles.successDescription}>{t('resetPass.success')}</p>
                </div>
                :
                <div className={`flex-container flex-grow base-container`}>
                    <div>
                        <h1>{t('resetPass.title')}</h1>
                        <p>{t('resetPass.description')}</p>
                    </div>

                    {feedback?.status === 'error' &&
                        <div style={{marginTop: '1.5rem'}}>
                            <StatusDiv dismiss={() => setFeedback()}
                                       statusObject={{
                                           icon: 'report',
                                           rectangleColor: 'red',
                                           text: feedback.message,
                                       }}
                            />
                        </div>
                    }
                    <ResetPasswordForm setFeedback={setFeedback} resetToken={resetToken}/>
                </div>
            }
        </BasePage>
    )
}

export default ResetPassword;
