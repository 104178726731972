import {ReactComponent as AmiabilaColoredLogo} from "../../assets/images/svg/AmiabilaColoredLogo.svg";
import styles from './styles/footer.module.css';
import FooterNavlinks from "../navlinks/FooterNavlinks";

const Footer = () => {
    return (
        <div className={styles['footer-container']}>
            <AmiabilaColoredLogo className={styles['logo']}/>
            <FooterNavlinks/>
        </div>
    )
}

export default Footer;
