import {useEffect, useRef, useState} from "react";

// initialCount is in minutes
const Timer = ({initialCount}) => {
    const [secondsRemaining, setSecondsRemaining] = useState(initialCount)

    const secondsToDisplay = secondsRemaining % 60
    const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60
    const minutesToDisplay = minutesRemaining % 60

    useInterval(
        () => {
            if (secondsRemaining > 0) {
                setSecondsRemaining(secondsRemaining - 1)
            }
        }, 1000,
        // passing null stops the interval
    )
    return (
        <p>{twoDigits(minutesToDisplay)}:{twoDigits(secondsToDisplay)}</p>
    )
}

function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current()
        }

        if (delay !== null) {
            let id = setInterval(tick, delay)
            return () => clearInterval(id)
        }
    }, [delay])
}

const twoDigits = (num) => String(num).padStart(2, '0')

export default Timer;
