export const membriilist = [
    {
        title: 'membrii.Allianz.name',
        description: 'membrii.Allianz.adresa',
        image:  require('../../../assets/images/png/partners/logo_allianz.png'),
        direction: 'left',
        phoneNr: 'membrii.Allianz.telefon',
        email: 'membrii.Allianz.email',
        fax: 'membrii.Allianz.fax',
        web: 'membrii.Allianz.web',
        //linkAvizare:'membrii.Allianz.linkAvizare'
    },
    {
        title: 'membrii.Asirom.name',
        description: 'membrii.Asirom.adresa',
        image:  require('../../../assets/images/png/partners/logo_asirom.png'),
        direction: 'left',
        phoneNr: 'membrii.Asirom.telefon',
        email: 'membrii.Asirom.email',
        fax: 'membrii.Asirom.fax',
        web: 'membrii.Asirom.web',
        //linkAvizare:'membrii.Asirom.linkAvizare'
    },
    {
        title: 'membrii.Generali.name',
        description: 'membrii.Generali.adresa',
        image:  require('../../../assets/images/png/partners/logo_generali.png'),
        direction: 'left',
        phoneNr: 'membrii.Generali.telefon',
        email: 'membrii.Generali.email',
        fax: 'membrii.Generali.fax',
        web: 'membrii.Generali.web',
        //linkAvizare:'membrii.Generali.linkAvizare'
    },
    {
        title: 'membrii.Grawe.name',
        description: 'membrii.Grawe.adresa',
        image:  require('../../../assets/images/png/partners/logo_grawe.png'),
        direction: 'left',
        phoneNr: 'membrii.Grawe.telefon',
        email: 'membrii.Grawe.email',
        fax: 'membrii.Grawe.fax',
        web: 'membrii.Grawe.web',
        //linkAvizare:'membrii.Grawe.linkAvizare'
    },
    {
        title: 'membrii.Groupama.name',
        description: 'membrii.Groupama.adresa',
        image:  require('../../../assets/images/png/partners/Logo-Groupama.png'),
        direction: 'left',
        phoneNr: 'membrii.Groupama.telefon',
        email: 'membrii.Groupama.email',
        //fax: 'membrii.Groupama.fax', eliminat conform cerinta din 07.08.2023, CR - C000511
        web: 'membrii.Groupama.web',
        linkAvizare:'membrii.Groupama.linkAvizare'
    },
    {
        title: 'membrii.Omniasig.name',
        description: 'membrii.Omniasig.adresa',
        image:  require('../../../assets/images/png/partners/logo_omniasig.png'),
        direction: 'left',
        phoneNr: 'membrii.Omniasig.telefon',
        email: 'membrii.Omniasig.email',
        fax: 'membrii.Omniasig.fax',
        web: 'membrii.Omniasig.web',
        //linkAvizare:'membrii.Omniasig.linkAvizare'
    },
    {
        title: 'membrii.Axeria.name',
        description: 'membrii.Axeria.adresa',
        image:  require('../../../assets/images/png/partners/logo_axeria.png'),
        direction: 'left',
        phoneNr: 'membrii.Axeria.telefon',
        email: 'membrii.Axeria.email',
        web: 'membrii.Axeria.web',
        //linkAvizare:'membrii.Axeria.linkAvizare'
    },
    
    {
        title: 'membrii.HellasDirect.name',
        description: 'membrii.HellasDirect.adresa',
        image:  require('../../../assets/images/png/partners/logo_hellas.png'),
        direction: 'left',
        email: 'membrii.HellasDirect.email',
        web: 'membrii.HellasDirect.web',
       // linkAvizare:'membrii.HellasDirect.linkAvizare'
    },

    {
        title: 'membrii.Eazy.name',
        description: 'membrii.Eazy.adresa',
        image:  require('../../../assets/images/png/partners/logo_eazy.png'),
        direction: 'left',
        email: 'membrii.Eazy.email',
        web: 'membrii.Eazy.web',
        phoneNr: 'membrii.Eazy.telefon',
       // linkAvizare:'membrii.Eazy.linkAvizare'
    },
    
     /*
    {
        title: 'membrii.Zastrahovatelno.name',
        description: 'membrii.Zastrahovatelno.adresa',
        image:  require('../../../assets/images/png/partners/logo_dallbogg.png'),
        direction: 'left',
        phoneNr: 'membrii.Zastrahovatelno.telefon',
        email: 'membrii.Zastrahovatelno.email',
        fax: 'membrii.Zastrahovatelno.fax',
        web: 'membrii.Zastrahovatelno.web'
    },
    */
]
