export const steps = [
    {
        title: 'tutorial.firstTitle',
        description: 'tutorial.firstDescription',
        image: require('../../../assets/images/png/tutorial/Login Amiabila Mockup_2 2.png'),
        direction: 'left',
    },
    {
        title: 'tutorial.secondTitle',
        description: 'tutorial.secondDescription',
        image: require('../../../assets/images/png/tutorial/Register Mockup 2.png'),
        direction: 'right',
    },
    {
        title: 'tutorial.thirdTitle',
        description: 'tutorial.thirdDescription',
        image: require('../../../assets/images/png/tutorial/Constatari 1.png'),
        direction: 'left',
    },
    {
        title: 'tutorial.fourthTitle',
        description: 'tutorial.fourthDescription',
        image: require('../../../assets/images/png/tutorial/Buletin & Date 1.png'),
        direction: 'right',
    },
    {
        title: 'tutorial.fifthTitle',
        description: 'tutorial.fifthDescription',
        image: require('../../../assets/images/png/tutorial/Buletin & Date 2.png'),
        direction: 'left',
    },
    {
        title: 'tutorial.sixthTitle',
        description: 'tutorial.sixthDescription',
        image: require('../../../assets/images/png/tutorial/Buletin & Date 3.png'),
        direction: 'right',
    },
    {
        title: 'tutorial.seventhTitle',
        description: 'tutorial.seventhDescription',
        image: require('../../../assets/images/png/tutorial/Buletin & Date 4.png'),
        direction: 'left',
    },
    {
        title: 'tutorial.eighthTitle',
        description: 'tutorial.eighthDescription',
        image: require('../../../assets/images/png/tutorial/Buletin & Date 5.png'),
        direction: 'right',
    },
    {
        title: 'tutorial.ninthTitle',
        description: 'tutorial.ninthDescription',
        image: require('../../../assets/images/png/tutorial/Buletin & Date 6.png'),
        direction: 'left',
    },
    {
        title: 'tutorial.tenthTitle',
        description: 'tutorial.tenthDescription',
        image: require('../../../assets/images/png/tutorial/Buletin & Date 7.png'),
        direction: 'right',
    },
]
