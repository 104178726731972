import styles from './styles/termsandconditions.module.css';

const TermsAndConditions = () => (
   
        <div className={`flex-container flex-grow ${styles.container}`}>
          <div style={{textAlign: "justify", marginLeft:"40px", marginRight:"40px"}}>
    <div>
        <h4 style={{textAlign: "center", marginTop:"15px", marginBottom:"80px"}}>Termeni si condiții privind furnizarea serviciilor aplicației AMIABILA</h4>
        <h4>1. Definirea termenilor contractuali</h4>
        In &icirc;nțelesul prezentului Contract termenii de mai jos au următoarele semnificații:
        <p><strong>BAAR</strong> - Asociatia Biroul Asiguratorilor de Autovehicule din Romania cu sediul in Bucuresti,
            Sector 2,
            Str. Vasile Lascar nr.40-40bis, et. 5 si 6, CUI RO7681180, asociatie profesionala, independenta si autonoma
            a
            tuturor societatilor de asigurare, indiferent de forma de organizare si de statul in care isi au sediul
            social,
            care, in baza legii, au dreptul sa practice in Romania asigurarea obligatorie de raspundere civila pentru
            prejudicii
            produse tertilor prin accidente de vehicule si care indeplineste atributiile de birou national auto, de
            organism
            de
            plata a despagubirilor, de organism de compensare, de centru de informare, de a asigura dezvoltarea si
            administrarea
            bazei de date privind evidenta contractelor RCA in ceea ce priveste colectarea, administrarea, procesarea,
            prelucrarea, managementul calitatii si publicarea datelor referitoare la (i) politele de asigurare
            obligatorie
            de
            raspundere civila auto; (ii) comportamentul de daunalitate si de risc al proprietarilor si utilizatorilor de
            vehicule aferente politelor RCA, precum si alte atributii conferite prin lege.</p>
        <p>Ca forma juridica de organizare,&nbsp;<strong>Biroul Asiguratorilor de Autovehicule din Romania
                (BAAR)</strong>este o
            asociatie profesionala constituita in baza&nbsp;<em>Ordonantei nr. 26/2000 cu privire la asociatii si
                fundatii,</em>
            inregistrata in Registrul Asociatiilor si Fundatiilor, aflat la grefa Judecatoriei sectorului 2 Bucuresti,
            sub
            numarul 132/26.11.2002. Asociatia are ca membri toate societatile de asigurare care au dreptul sa practice
            in
            Romania asigurarea obligatorie de raspundere civila auto pentru prejudicii produse tertilor prin accidente
            de
            vehicule si de tramvaie (RCA).</p>
        <p>Date de contact:</p>
        <ul>
            <li>Telefon: (+40) 0311 300 800</li>
            <li>Email: contact@amiabila.com</li>
        </ul>
        <p><strong>Asigurator RCA </strong>- asigurator autorizat sa practice asigurarea RCA pe teritoriul
            Rom&acirc;niei.
        </p>
        <p><strong>Lista Asiguratorilor RCA cu datele de contact poate fi consultata <a
                    href="https://amiabila.com/membrii">aici.</a></strong></p>

        <p><strong>CONSTATARE AMIBAILĂ DE ACCIDENT </strong>reprezintă descrierea unui cumul de elemente şi fapte care
            contribuie la soluționarea dosarelor de daună, nereprezent&acirc;nd o recunoaștere a răspunderii
            conducătorilor
            auto.</p>
        <p><strong>AIDA </strong>- Baza de date cu asigurarile obligatorii de raspundere civila auto incheiate pe
            teritoriul
            Romaniei, care este alimentata de asiguratorii RCA cu informatiile prevazute de lege in legatura cu
            contractele
            de
            asigurare RCA incheiate.</p>
        <p><strong>Polita de asigurare RCA </strong>- documentul prin care se constata incheierea contractului de
            asigurare
            RCA
            si se atesta existenta asigurarii de raspundere civila pentru prejudicii produse tertelor persoane prin
            accidente de
            vehicule si de tramvaie.</p>
        <p><strong>Asigurat RCA</strong> - proprietarul sau utilizatorul unui vehicul sau al unui tramvai a carui
            raspundere
            civila delictuala este preluata contractual de un asigurator RCA in baza contractului RCA valabil pentru
            prejudiciile produse tertelor persoane din accidente de vehicule/tramvaie, <strong>inscris in polita de
                asigurare</strong>.</p>
        <p><strong>Utilizator RCA </strong>- persoana fizica sau juridica, careia proprietarul vehiculului ii acorda
            dreptul
            de
            folosinta asupra acestuia, pe o anumita perioada, in baza unui contract de inchiriere, contract de leasing
            sau
            alt
            act intocmit in conditiile legii, <strong>inscris in polita de asigurare</strong>.</p>
        <p>Amiabila &ndash; Aplicația informatică dezvoltată și administrată de BAAR &icirc;mpreună cu asiguratorii RCA.
        </p>
        <p><strong>Client</strong> - vizitatorul aplicatiei care doreste inrolarea in aplicatia AMIABILA.</p>
        <p><strong>Utilizatorul aplicatiei</strong> - clientul care si-a deschis un cont in aplicatia AMIABILA.</p>
        <p><strong>Contractul </strong>- Contractul privind furnizarea serviciului AMIABILA.</p>
        <p><strong>Serviciul </strong>- reprezinta serviciul furnizat Clientului de catre Asiguratorii RCA și BAAR prin
            punerea
            la dispozitia acestuia a aplicatiei AMIABILA, care permite Clientului utilizarea funcționalităților
            aplicației.
            Serviciul este disponibil prin descarcarea aplicatiei AMIABILA pentru tableta/smartphone din App Store sau
            Play
            Store si/ sau intr-o interfata web. Acesarea serviciului se efectueaza pe baza Elementelor de securitate.
        </p>
        <p><strong>Elementele de securitate</strong> - caracteristicile personalizate furnizate
            Clientului/Utilizatorului
            aplicatiei de catre Asiguratorii RCA și BAAR &icirc;n scopul identificarii, autentificarii, semnarii si
            securizarii
            datelor si instructiunilor transmise pe parcursul comunicatiei electronice. Elementele de securitate
            utilizate
            pot
            fi urmatoarele:</p>
        <ol>
            <li>a) verificarea numarului de telefon mobil indicat de Client in timpul inrolarii in aplicatie prin
                transmiterea
                unui cod prin SMS, si</li>
            <li>b) verificarea adresei de email indicate de Client in timpul &icirc;nrolării in aplicație, prin
                transmiterea
                unui email de validare a contului la aceeași adresa de email.</li>
        </ol>
        <p><strong>Inrolarea in aplicatie</strong> - procesul de &icirc;nregistrare, prin care orice persoana isi poate
            crea
            un
            cont de utilizator, in baza caruia poate accesa aplicatia AMIABILA, utilizand anumite credentiale (user si
            parola).
        </p>
        <p><strong>Autentificarea in aplicatie</strong> - autentificarea este procesul de verificare a identitatii
            digitale
            a
            unei persoane care a realizat anterior inrolarea in aplicatia AMIABILA si care doreste accesarea acesteia,
            prin
            utilizarea credentialelor stabillite la inrolarea in aplicatie, de tip user + parola.</p>
        <p><strong>Prejudiciu/Dauna </strong>- efectul negativ suferit de către persoana prejudiciata prin producerea
            unui
            risc
            acoperit printr-un contract RCA.</p>
        <p><strong>Eveniment </strong>- accidentul de vehicule care a avut drept consecința producerea unui prejudiciu /
            unei
            daune.</p>
        <h4>2. Introducere</h4>
        <p><em>2.1. Termenii si conditiile </em><em>de utilizare a aplicatiei AMIABILA</em> stabilesc modul in care
            aplicatia
            &ldquo;AMIABILA&rdquo;&nbsp;functioneazea, precum si conditiile in care Clientul/Utilizatorul aplicatiei va
            putea
            crea contul, accesa informatiile și dispune de acestea.</p>
        <p>2.2. Prin accesarea, navigarea si/sau utilizarea acestei aplicații, confirmați ca ați citit, ati inteles si
            sunteti
            de acord sa respectați <em>Termenii si condițiile de utilizare a aplicației AMIABILA</em>. Daca nu sunteți
            de
            acord
            cu acești termeni, nu veți putea utiliza aplicația AMIABILA.</p>
        <p>2.3. Utilizatorul are obligatia de a analiza cu atentie acesti <em>Termeni si conditii</em> de fiecare data
            cand
            intentioneaza sa utilizeze aplicatia AMIABILA, pentru a avea cunostinta despre regulile de utilizare si
            pentru a
            se
            asigura ca este in cunostinta de cauza cu Termenii si conditiile care se aplica la momentul accesarii.</p>

        <h4>3. Obiectul Contractului</h4>

        <p>3.1. Obiectul prezentului contract il constituie furnizarea de către Asiguratorii RCA și BAAR a serviciului
            AMIABILA,
            prin punerea la dispoziție a platformei electronice, la solicitarea Clientului/Utilizatorului aplicației,
            pentru
            efectuarea operațiunilor enunțate mai jos, in condițiile convenite intre parti si prin raportare la
            urmatoarele
            reglementari:</p>
        <ul>
            <li>Legea nr. 132/2017 privind asigurarea obligatorie de raspundere civila pentru prejudicii produse
                tertilor
                prin
                accidente de vehicule si tramvaie;</li>
            <li>Norma Autoritatii de Supraveghere Financiara nr. 20/2017 privind asigurarile auto din Romania;</li>
            <li>Regulamentul (UE) 2016/679 al Parlamentului European si al Consiliului din 27 aprilie 2016 privind
                protectia
                persoanelor fizice in ceea ce priveste prelucrarea datelor cu caracter personal si privind libera
                circulatie
                a
                acestor date si de abrogare a Directivei 95/46/CE (Regulamentul general privind protectia datelor) si
                ale
                legii
                nr. 190/2018.</li>
        </ul>
        <p>3.2. Asiguratorii RCA și BAAR pun la dispoziția asiguraților, &icirc;n mod gratuit, prin intermediul
            aplicației
            AMIABILA, mijloacele electronice necesare pentru &icirc;ncheierea &ldquo;Constatării amiabile de
            accident&rdquo;
            &icirc;n format electronic.</p>
        <p>3.3. Prin serviciul AMIABILA pot fi efectuate următoarele operațiuni, enumerarea fiind exemplificativa si nu
            limitativa:</p>
        <ol>
            <li>Generarea și descărcarea Constatării amiabile de accident;</li>
            <li>Notificarea asiguratorilor RCA cu privire la &icirc;ncheierea unei Constatării amiabile de accident;
            </li>
            <li>Vizualizarea constatărilor amiabile de accident &icirc;ncheiate de utilizatorul aplicației;</li>
        </ol>
        <p>3.4. Accesarea serviciului AMIABILA, pentru oricare dintre operatiunile de mai sus si initierea si
            autorizarea de
            instructiuni, se realizeaza prin intermediul Elementelor de securitate definite in capitolul 1 din prezentul
            Contract.</p>
            <p>3.5. Serviciul AMIABILA include funcționalități suplimentare față de cele descrise la punctele anterioare, 
            cum ar fi, fără a prezenta o listă limitativă, setarea de către utilizator de alerte la expirarea anumitor documente, 
            contracte sau servicii sau posibilitatea de completare a unui contract de vânzare – cumpărare a unui autovehicul.</p>
        <h4>4. Scopurile prelucrării si datele prelucrate</h4>
        <p>4.1. Pentru executarea prezentului contract, Asiguratorii RCA care acoperă răspunderea civilă delictuală a
            conducătorilor auto implicați &icirc;n accidentul de circulație ce face obiectul Constatării amiabile de
            accident și
            BAAR după caz prelucrează datele menționate mai jos, pentru atingerea următoarelor scopuri:</p>
        <ol>
            <li>&icirc;nrolarea Clienților in aplicația AMIABILA</li>
            <li>autentificarea Utilizatorilor in aplicația AMIABILA</li>
            <li>generarea Constatării amiabile de accident prin intermediul aplicației AMIABILA</li>
            <li>notificarea asiguratorilor RCA ai vehiculelor implicate &icirc;n evenimentul rutier</li>
            <li>prevenirea fraudelor si &icirc;mbunătățirea serviciilor aplicației AMIABILA</li>
        </ol>
        <p>4.2. Pentru generarea Constatării amiabile de accident și notificarea asiguratorilor RCA, pot fi prelucrate
            următoarele date ale persoanei vizate (Clientul):</p>
        <ul>
            <li>data și localizare eveniment,</li>
            <li>nume si prenume conducători auto, martori, proprietari sau deținători auto,</li>
            <li>număr telefon mobil proprietar / conducător auto, martori,</li>
            <li>adresa de e-mail, adresă domiciliu,</li>
            <li>fotografii ale daunei, sau alte fotografii &icirc;ncărcate de utilizatori,</li>
            <li>seria si numarul CI/BI / pasaport / card diplomatic,</li>
            <li>data expirarii CI/BI / pasaport / card diplomatic,</li>
            <li>numărul permisul de conducere, categorie, data expirării / certificatul de &icirc;nmatriculare a
                vehiculului,
            </li>
            <li>Serie caroserie și număr &icirc;nmatriculare auto,</li>
            <li>Serie și număr poliță asigurare RCA, perioada valabilitate, asigurator și emitent,</li>
            <li>Existență asigurare casco,</li>
            <li>Coordonate GPS,</li>
            <li>Semnături.</li>
        </ul>
        <p>Scopul prelucrării acestor date este reprezentat de identificarea persoanelor care se &icirc;nregistrează in
            aplicația AMIABILA precum și de respectarea cerințelor legale privind &icirc;ncheierea Constatării amiabile
            de
            accident &icirc;n format electronic.</p>
        <p>Furnizarea numărului de telefon si a adresei de e-mail are ca scop at&acirc;t stabilirea identității
            clientului,
            cat
            si stabilirea unui mijloc de comunicare cu acesta.</p>
        <p>4.3. Pentru autentificarea Utilizatorilor in aplicația AMIABILA sunt prelucrate următoarele date ale
            persoanei
            vizate
            (Utilizatorul):</p>
        <ul>
            <li>nume de utilizator,</li>
            <li>parola;</li>
        </ul>
        <p>Scopul prelucrării acestor date este reprezentat de asigurarea accesului la date doar persoanelor
            &icirc;ndreptățite
            sa le acceseze.</p>
        <p>4.4. Pentru prevenirea fraudelor si &icirc;mbunătățirea serviciilor aplicației AMIABILA sunt prelucrate
            următoarele
            date ale dispozitivului electronic pe care se realizează &icirc;nrolarea si/sau autentificarea:</p>
        <ul>
            <li>adresa de email a utilizatorului,</li>
            <li>numărul de telefon al utilizatorului,</li>
        </ul>
        <p>Scopul prelucrării acestor date este reprezentat de identificarea utilizarii neautorizate a datelor
            Utilizatorilor,
            descoperirea fraudelor informatice in legatura cu conturile.</p>
        <p>4.5. Conform Normei ASF nr.20/2017,</p>
        <p>(1) Pentru evenimentele &icirc;n care au fost implicate două vehicule asigurate, din care au rezultat numai
            prejudicii materiale, avizarea daunei se poate face şi &icirc;n baza formularului "Constatare amiabilă de
            accident".
        </p>
        <p>(2) Completarea şi semnarea formularului "Constatare amiabilă de accident" reprezintă descrierea unui cumul
            de
            elemente şi fapte care contribuie la soluţionarea dosarelor de daună, nereprezent&acirc;nd o recunoaştere a
            răspunderii conducătorilor auto.</p>
        <p>(3) Formularul &laquo;Constatare amiabilă de accident&raquo; poate fi utilizat &icirc;n relaţia cu
            asigurătorii
            fără
            a mai fi necesară &icirc;ntocmirea documentelor de constatare de către poliţia rutieră, numai dacă este
            completat şi
            semnat pe suport h&acirc;rtie sau electronic prin intermediul solutiei tehnice dezvoltata si administrata de
            catre
            Asiguratoii RCA si BAAR, de către ambii conducători de vehicule implicaţi &icirc;n accident.</p>
        <p>(4) &Icirc;n cazul completării formularului de &laquo;Constatare amiabilă de accident&raquo; &icirc;n format
            electronic, datele completate şi semnăturile olografe ale conducătorilor celor două vehicule implicate
            &icirc;n
            accident sunt preluate de dispozitivul pe care se completează formularul.</p>
        <p>(5) Asigurătorii RCA şi BAAR dezvoltă şi administrează soluțiile tehnice şi stabilesc de comun acord
            procedura
            avizării daunei prin intermediul formularului de &laquo;Constatare amiabilă de accident&raquo; &icirc;n
            format
            electronic, cu respectarea dispozițiilor legale privind gestionarea riscurilor operaționale generate de
            sistemele
            informatice utilizate de entitățile autorizate/avizate/&icirc;nregistrate, reglementate şi/sau supravegheate
            de
            către A.S.F.</p>
        <p>(6) Formularul &laquo;Constatare amiabilă de accident&raquo; &icirc;n format electronic reproduce acelaşi
            model
            grafic, conţine aceleaşi informaţii şi are aceeaşi valoare juridică cu cea a formularului tipărit.</p>
        <p>4.6. Perioadele de menținere a informațiilor &icirc;n infrastructura AMIABILA sunt cele stabilite prin lege,
            iar
            &icirc;n lipsa unor reglementări specifice &icirc;n acest sens, acestea sunt după cum urmează:</p>
        <ol>
            <li>5 ani calendaristici &icirc;ntregi ulteriori datei la care s-a &icirc;ncheiat &bdquo;Constatarea
                amiabilă de
                accident&rdquo; &icirc;n format electronic pentru fotografii și informațiile conținute &icirc;n
                formularul
                constatării amiabile de accident;</li>
            <li>Două zile de la data deschiderii unei noi constatări amiabile electronice pentru acele constatări
                completate
                parțial sau total dar &icirc;ncă nevalidate de ambele părți/conducători auto implicate/implicați
                &icirc;n
                accident.</li>
            <li>Nelimitat sau p&acirc;nă la ștergerea contului de client pentru informațiile de autentificare &icirc;n
                aplicația
                AMIABILA;</li>
            <li>Nelimitat, &icirc;n scopuri statistice, pentru celelalte informații care nu sunt menționate la lit. a)
                și
                b);
            </li>
        </ol>
        <p>La &icirc;mplinirea termenelor menționate, BAAR efectuează ștergerea informațiilor din
            infrastructura AMIABILA.</p>
        <p>4.7. Datele cu caracter personal și informațiile tehnice utilizate de părți în cadrul procesului de completare a contractul de vânzare – cumpărare a unui vehicul 
            sunt salvate temporar doar pe dispozitivul utilizat pentru redactare, pâna la generarea și descărcarea sau transmiterea documentului către adresele de e-mail 
            ale vânzătorului, respectiv cumpărătorului. BAAR și Asigurătorii RCA nu au acces la informațiile și datele cu caracter personal utilizate în cadrul acestui proces. 
            Din rațiuni care țin de securitate și minimizarea prelucrării datelor cu caracter personal, după descărcarea sau transmiterea către adresele de e-mail anterior menționate, 
            utilizatorul serviciului AMIABILA nu va mai putea regăsi în istoricul aplicației contractul de vânzare – cumpărare finalizat.
        </p>
        <h4>5. Drepturi de autor si marca</h4>
        <p>5.1. Aplicația AMIABILA este proprietatea BAAR, prin urmare are dreptul patrimonial exclusiv de a autoriza si
            de
            a
            interzice extragerea si/sau reutilizarea totalității sau a unei părți substanțiale din aceasta, evaluata
            calitativ
            sau cantitativ, beneficiind de protectia legii fata de orice utilizare neautorizata a acestei aplicații.</p>
        <p>5.2. Conținutul si design-ul aplicației, inclusiv look&amp;feel-ul acestuia si bazele de date accesibile prin
            intermediul sau, sunt proprietatea BAAR si sunt protejate prin legislația romana in vigoare cu privire la
            drepturile
            de autor si drepturile conexe. Monograma, logo-ul si elementul grafic distinctiv prezentate in aplicatia
            AMIABILA
            sunt, de asemenea, marci inregistrate si nu pot fi utilizate decat cu acordul scris al BAAR.</p>
        <p>5.3. BAAR detine asupra marcilor AIDA, AMIABILA și BAAR drepturi exclusive, in conformitate cu Legea nr.
            84/1998
            privind marcile si indicatiile geografice, fiind inregistate la OSIM.</p>
        <p>5.4. Este interzisa orice utilizare a conținutului in alte scopuri decat cele permise expres de prezentul
            contract
            sau de legislația in vigoare. In consecința, nicio utilizare, preluare, replicare sau publicare a mărcii
            AMIABILA,
            sub nicio forma, prezentare ori scop, pe niciun suport fizic sau informatic nu este autorizata fara a detine
            acordul
            scris prealabil al reprezentantilor legali ai BAAR.</p>
        <p>5.5. In caz de incalcare a interdictiilor mentionate mai sus, BAAR va proceda la efectuarea demersurilor
            legale
            pe
            cale civila si/sau penala impotriva celor raspunzatori in vederea protejării drepturilor BAAR asupra
            aplicației
            AMIABILA, a mărcii AMIABILA si a celorlalte imagini, ilustrații, design, simboluri, a stopării
            &icirc;ncălcării
            acestora si reparării prejudiciului suferit.</p>

        <h4>6. Drepturile si obligațiile părților. Modificarea contractului</h4>

        <p>6.1. Aplicația AMIABILA permite &icirc;nregistrarea si accesul la un cont de Utilizator al aplicației.</p>
        <p>6.2. Asiguratorii RCA și BAAR pun exclusiv la dispoziția Clientului/Utilizatorului aplicației Elementele de
            securitate pentru utilizarea serviciului AMIABILA.</p>
        <p>6.3. Asiguratorii RCA și BAAR pot solicita Utilizatorului autentificarea cu Parola dupa un anumit numar de
            autentificari sau dupa un anumit interval de timp de la ultima utilizare a Parolei. Asiguratorii RCA și BAAR
            &icirc;și rezervă dreptul de a schimba si actualiza, in orice moment, conținutul aplicației AMIABILA, precum
            si
            Termenii si condițiile de utilizare a aplicației AMIABILA, Utilizatorul aplicației urm&acirc;nd a fi
            notificat
            despre aceasta.</p>
        <p>6.4. Clientul/Utilizatorul aplicației va utiliza serviciul AMIABILA prin intermediul propriilor dispozitive
            electronice (ex: smartphone sau tableta.), pe care rulează sisteme de operare Android si IOS.</p>
        <p>6.5. Utilizatorul aplicatiei se obliga sa nu copieze, v&acirc;ndă, &icirc;nchirieze, sa &icirc;nstrăineze sub
            orice
            alta forma ori sa permită utilizarea de către persoane neautorizate a serviciului AMIABILA sau a Elementelor
            de
            securitate.</p>
        <p>6.5. Utilizatorul aplicației se obliga sa păstreze confidențialitatea cu privire la Elementele de securitate
            pe
            &icirc;ntreaga durata a Contractului.</p>
        <p>6.6. Utilizatorul aplicației se obligă să utilizeze serviciul AMIABILA cu respectarea tuturor prevederilor
            prezentului Contract.</p>
        <p>6.7. Utilizatorul aplicației &icirc;și exprima acordul pentru executarea Contractului &icirc;ncheiat la
            distanta
            la
            prima accesare a Serviciului AMIABILA, prin finalizarea procedurilor de creare a contului.</p>
        <p>6.8. Utilizarea continua a aplicației / contului de utilizator va fi considerata acceptarea oricărui conținut
            al
            celor menționate mai sus, in forma actualizata.</p>
        <p>6.9. In caz de divergenta sau neintelegeri intre Asiguratorii RCA sau BAAR pe de o parte si Utilizatorul
            aplicatiei
            pe de alta, se vor aplica <em>Termenii si conditiile de utilizare a aplicatiei AMIABILA</em> valabile la
            momentul
            survenirii divergentei sau ne&icirc;nțelegerii.</p>
        <p>6.10. Asiguratorii RCA și BAAR nu garantează faptul ca aplicația, serverele pe care este găzduită&nbsp;sau
            e-mailurile trimise prin intermediul aplicației sunt fără viruși sau alte componente informatice cu caracter
            potențial dăunător. Utilizatorul aplicației folosește aplicația pe riscul propriu, Asiguratorii RCA și BAAR
            fiind
            exonerați de orice răspundere pentru eventualele daune directe sau indirecte cauzate de utilizarea sau
            accesarea
            aplicatiei sau ca urmare a utilizării informațiilor din aceasta.</p>
        <p>6.11. Asiguratorii RCA și BAAR nu sunt răspunzători de erorile sau omisiunile care pot interveni in istoricul
            Utilizatorului aplicației și nici pentru informațiile, datele cu caracter personal ale utilizatorului sau
            ale
            altor
            persoane precum și fotografiile &icirc;ncărcate de utilizatori &icirc;n aplicația AMIABILA.</p>
        <p>6.12. Asiguratorii RCA și BAAR nu sunt responsabili pentru niciun fel de pagube decurg&acirc;nd din
            utilizarea
            neautorizata a serviciului AMIABILA (fie ca Elementele de Securitate primite de catre Utilizatorul
            aplicatiei au
            fost pierdute, furate sau utilizate fara drept) in cazul in care Utilizatorul aplicatiei a actionat in mod
            fraudulos
            sau nu si-a respectat obligatiile contractuale referitoare la utilizarea serviciului AMIABILA si pastrarea
            Elementelor de Securitate, cu intentie sau din neglijenta grava.</p>
        <p>6.13. Asiguratorii RCA și BAAR nu poarta răspunderea pentru nicio pierdere si pentru niciun prejudiciu
            suferite
            de
            Utilizatorul aplicației, daca:</p>
        <ol>
            <li>Utilizatorul aplicatiei este impiedicat sa acceseze in orice moment serviciul AMIABILA din orice cauze,
            </li>
            <li>informațiile recepționate de catre Utilizatorul aplicației prin serviciul AMIABILA sunt incorecte,
                incomplete
                sau &icirc;nt&acirc;rziate.</li>
        </ol>
        <p>6.14. Asiguratorii RCA și BAAR nu sunt responsabili pentru securitatea dispozitivelor (calculatoare /
            laptopuri /
            smartphone-uri / tablete) de pe care Utilizatorul aplicației accesează serviciul AMIABILA si pentru niciun
            fel
            de
            pagube / pierderi decurg&acirc;nd din utilizarea serviciului AMIABILA de pe calculatoare / laptopuri /
            smartphone-uri nesecurizate sau infectate cu viruși, smartphone-uri sau tablete cu modificări ce pot afecta
            securitatea dispozitivului si/sau infectate cu viruși si alte programe similare.</p>
        <p>6.15. Utilizatorul aplicației isi asuma responsabilitatea cu privire la păstrarea si utilizarea Elementelor
            de
            securitate si la securitatea dispozitivelor de pe care accesează serviciul AMIABILA si va suporta toate
            pierderile
            si riscurile decurg&acirc;nd din divulgarea Elementelor de securitate sau utilizarea de către terțe persoane
            a
            serviciului AMIABILA.</p>
        <p>6.16. Scopul permis pentru utilizarea prezentei aplicații este cel prevăzut de lege și de prezentul Contract
            si
            accesul la serviciile oferite prin intermediul acesteia. Orice utilizare in alte scopuri decat acestea nu
            este
            permisa. Aplicatia permite descarcarea Constatării amiabile de accident in format PDF.</p>
        <p>6.17. Dupa introducerea si verificarea adresei de email, Utilizatorului aplicației i se va transmite o
            notificare
            la
            adresa de email, prin utilizarea unor servicii prestate de terti, care implica inclusiv comunicarea adresei
            de
            email
            catre acestia. Notificarea va contine un cod de confirmare pe care Utilizatorul aplicatiei il va introduce
            in
            aplicatia AMIABILA pentru autorizarea contului sau.</p>
        <p>6.18. Pe durata de viață a aplicatiei AMIABILA, la adresa de email si/sau in aplicatie Utilizatorul va primi
            notificări specifice scopului aplicației.</p>
        <p>6.19. Solicitările persoanelor interesate referitoare la modificări ale informațiilor privind contractele de
            asigurare RCA sau istoricul de daunalitate, răspunderea pentru producerea accidentelor de circulație,
            avizarea
            daunelor și soluționarea pretențiilor de despăgubire vor fi soluționate direct de către Asiguratorii RCA
            care au
            emis polițele de asigurare RCA pentru vehiculele implicate &icirc;n accidentul de circulație ce formează
            obiectul
            constatării amiabile de accident.</p>
        <p>6.20. Documentul Constatare amiabilă de accident &icirc;n format electronic generat prin intermediul
            platformei
            electronice AMIABILA reprezintă un &icirc;nscris pe suport informatic ca tip de &icirc;nscris lipsit de
            suport
            material, care conţine date şi informaţii cu privire la un act juridic, conform prevederilor art.282-284 Cod
            Procedură Civilă.</p>

        <h4>7. Securitatea informatică a aplicației AMIABILA</h4>
        <p>7.1. Pentru a preveni utilizarea neautorizata a serviciului AMIABILA, Utilizatorul:</p>
        <ol>
            <li>va păstra in siguranță Elementele de securitate, va lua toate masurile de precauție necesare pentru
                păstrarea
                confidențialității acestora, pentru prevenirea fraudei si/sau a utilizării lor
                neautorizate/necorespunzătoare,
            </li>
            <li>va accesa serviciul AMIABILA numai de pe dispozitive securizate (<em>de ex. smartphone-uri sau tablete
                    fara
                    modificări ce pot afecta securitatea dispozitivului</em>);</li>
        </ol>
        <p>7.2. Orice persoana care accesează serviciul AMIABILA este prezumata a fi Clientul/ Utilizatorul aplicației.
            Prin
            prezentul Contract, Clientul/Utilizatorul aplicației autorizează Asiguratorii RCA și BAAR să execute
            comenzile
            aplicației in momentul primirii acestora.</p>
        <p>7.3. Asiguratorii RCA și BAAR au dreptul sa nu acționeze conform comenzilor aplicației daca există suspiciuni
            de
            &icirc;ncălcare a securității.</p>
        <p>7.4. Accesul Clientului/Utilizatorului aplicației in platforma AMIABILA se face cu aplicarea procedurii de
            Autentificare stricta a clienților, prin utilizarea Elementelor de securitate corespunzătoare.</p>
        <p>7.5. Transferul informațiilor și datelor cu caracter personal, precum si procesul de autorizare, se
            realizează cu
            ajutorul protocoalelor de securitate.</p>
        <p>7.6. Asiguratorii RCA și BAAR depun eforturile constante pentru a asigura securitatea datelor transmise prin
            folosirea aplicației. Cu toate acestea, Asiguratorii RCA și BAAR nu garantează securitatea absolută a
            acestora.
        </p>
        <p>7.7. Prin utilizarea aplicației sunteți răspunzător/răspunzătoare pentru asigurarea confidențialității
            datelor
            privitoare la contul dumneavoastră de acces, cum ar fi <strong>numele de utilizator si parola</strong>.</p>
        <p>7.8. Asiguratorii RCA și BAAR nu &icirc;și asuma răspunderea pentru eventualele daune provocate de
            transmiterea,
            de
            către Utilizatorul aplicației, a parolei si a numelui de utilizator către terțe persoane. Utilizatorul
            aplicației
            este integral răspunzător de informațiile transmise folosind credențialele aferente. In cazul in care acesta
            suspectează că, din diverse motive imputabile sau nu propriei persoane, confidențialitatea acestor date de
            acces
            a
            fost compromisă, va genera cat mai rapid noi date de autentificare.</p>
        <p>7.9. Efectuarea de operațiuni neautorizate asupra aplicației AMAIABILA și tentativa de efectuare a acestora,
            incluz&acirc;nd fără a se limita la: utilizarea abuziva, copierea de informatii in vederea comercializării
            lor,
            utilizarea frauduloasa, modificarea, accesul neautorizat, cracking sau hacking, sau a atacurilor de genul
            &ldquo;negarea serviciului&rdquo;, blocarea accesului sau altele asemenea vor fi pedepsite conform legii.
            Utilizatorii care atacă securitatea sistemelor sau retelelor pot fi actionati in instanta, atragandu-se
            raspunderea
            civila si / sau penala.</p>
        <p>7.10. Aplicația AMIABILA poate oferi si legături / link-uri catre alte site-uri, considerate a fi utile.
            Aceste
            site-uri nu sunt susținute si / sau afiliate, cu serviciile aplicației AMIABILA.&nbsp;Deoarece aceste
            site-uri
            nu se
            afla sub autoritatea sau sub controlul administratorului aplicației AMIABILA, se declină orice răspundere
            privind
            conținutul acestora.</p>
        <h4>8. Neacordarea / suspendarea accesului</h4>
        <p>8.1. Aplicația AMIABILA este disponibilă gratuit, &icirc;nsă nu garantam ca aplicația sau orice conținut al
            acesteia
            va fi &icirc;ntotdeauna disponibil sau ne&icirc;ntrerupt.</p>
        <p>8.2. Serviciile AMIABILA pot fi suspendate sau accesul dvs. la continutul aplicatiei să fie blocat. In cazul
            in
            care
            un Utilizator al aplicatiei refuza sa furnizeze anumite informatii esentiale scopului accesarii, BAAR isi
            rezerva
            dreptul de a nu da curs cererii sale.</p>
        <p>8.3. &Icirc;nregistrarea Clientului &icirc;n aplicația mobilă si utilizarea serviciului poate fi refuzată,
            precum
            si
            blocarea imediată a utilizării serviciului de catre Utilizatorul aplicației, fara formalitati prealabile,
            dacă
            sunt
            suspectate activități ale acestuia care pun &icirc;n pericol securitatea aplicației sau a datelor conținute
            sau
            prelucrate prin intermediul acesteia.</p>
        <h4>9. Schimbări ale aplicației</h4>
        <p>9.1. Aplicația AMIABILA poate fi modificată, in orice moment. De asemenea, se poate restricționa accesul
            utilizatorilor la o parte sau la intregul sau continut. Noile conditii devin valabile in momentul in care
            sunt
            facute publice prin inscriere in acesta aplicatie si nu au caracter retroactiv. Asiguratorii RCA și BAAR nu
            isi
            asuma răspunderea pentru eventualele prejudicii suferite de o persoana ca urmare a unor decizii bazate pe
            informatii
            publicate sau vehiculate prin internet. Asiguratorii RCA și BAAR nu isi asuma responsabilitatea pentru
            informatiile
            publicate pe conturi de social media care nu apartin acestora si nici pentru eventualele probleme tehnice pe
            care le
            poate intampina aplicatia. Desi depunem eforturi rezonabile pentru a actualiza informatiile din aplicatie,
            nu
            facem
            niciun fel de declaratie sau garantie (explicit sau implicit), de niciun fel cu privire la corectitudinea,
            complexitatea conținutul din aplicația noastră.</p>
        <h4>10. &Icirc;nregistrare, parole si responsabilități</h4>
        <p>10.1. Avand in vedere faptul ca accesul Clientului/Utilizatorului la funcționalitățile din cadrul aplicației
            necesita
            &icirc;nregistrarea cu un nume de utilizator si o parola, recomandam acestora sa nu le divulge unor terte
            persoane,
            tratandu-le cu maxima confidențialitate. Dezvaluirea credentialelor de acces in aplicatie poate duce la
            distrugerea,
            pierderea, modificarea sau divulgarea neautorizata a datelor cu caracter personal transmise, stocate sau
            prelucrate
            intr-un alt mod, ori la accesul neautorizat la acestea de catre terte parti.</p>
        <p>10.2. AMIABILA nu va cere niciodată parola conturilor dvs in mesaje prin posta electronica sau telefon.</p>
        <p>10.3. Pentru siguranta datelor dvs. si pentru indeplinirea obligatiilor legale ce revin Asiguratoilor RCA și
            BAAR,
            toate interacțiunile cu aplicația sunt jurnalizate.</p>
        <h4>11. Prevederi finale</h4>
        <p>11.1. Prezentul Contract se incheie pe o perioada nedeterminata, urmand sa inceteze prin una din urmatoarele
            modalitati:</p>
        <ol>
            <li>in situatia in care Asiguratorii RCA și BAAR intrerup sau inceteaza furnizarea serviciului AMIABILA. In
                aceasta
                situatie Utilizatorul va fi notificat anterior intreruperii sau furnizarii serviciului;</li>
            <li>prin renuntarea la cont de catre Utilizator, exercitata in mod expres prin intermediul aplicatiei
                (stergerea
                contului):</li>
        </ol>
        <p>11.2. Prezentul Contract se &icirc;ncheie in limba rom&acirc;nă.</p>
        <p>11.3. Utilizatorul confirma in mod expres si in deplina cunostinta de cauza urmatoarele: (1) a analizat si
            s-a
            informat cu privire la obligatiile si conditiile acestui Contract, si (2) a citit, inteles si este de acord
            in
            totalitate cu Contractul privind furnizarea serviciului AMIABILA.</p>
    </div>  
    <div>
        <h4 style={{textAlign: "center", marginTop:"120px", marginBottom:"80px"}}>INFORMARE ADRESATA PERSOANELOR VIZATE CU
            PRIVIRE LA PRELUCRAREA DATELOR CU CARACTER PERSONAL</h4>
        <h4>PRIN INTERMEDIUL APLICATIEI AMIABILA</h4>
        <p>Raportat la prevederile art.13-14 din Regulamentul European privind Protectia Datelor cu Caracter Personal
            nr. 2016/679 (denumit in continuare &bdquo;<strong>Regulamentul</strong>&rdquo;), in cazul in care datele
            Dvs. cu caracter personal au fost obținute direct de la Dvs. sau prin intermediul unui reprezentant ori de
            la alte persoane fizice sau juridice de drept privat sau public, va aducem la cunoștința următoarele:</p>
        <ol start="1">
            <li><strong>Asiguratorii RCA </strong>ai persoanelor implicate in accidentul de circulație ce face obiectul
                unei constatări amiabile de accident &icirc;n format electronic<strong> și BAAR</strong> au calitatea de
                operatori de date cu caracter personal cu privire la prelucrarea datelor dvs. (denumiți in continuare in
                mod colectiv &bdquo;<strong>Operatorii</strong>&rdquo;).</li>
        </ol>
        <ol start="2">
            <li><strong>Datele de contact ale Operatorilor sunt după cu urmează</strong><strong>:</strong></li>
        </ol>
        <p>BAAR; protectiadatelor@baar.ro , Fax: 021/312.31.82;</p>
        <p>Lista Asiguratorilor RCA și a datelor de contact ale acestora se regăsește <a
                href="https://amiabila.com/membrii">aici.</a></p>
        <ol start="3">
            <li><strong>Scopul procesării datelor Dvs</strong>.: Aceasta notă de informare se aplica tuturor subiecților
                ale căror date cu caracter personal au fost &icirc;nregistrare si/sau &icirc;ncărcate in aplicația
                AMIABILA (de exemplu: asigurați, persoane păgubite, conducători auto, martori), conform Termenilor si
                Condițiilor prevăzute pentru furnizarea serviciului AMIABILA.</li>
            <li><strong>Categoriile de date cu caracter personal prelucrate</strong> sunt dupa caz, următoarele: data și
                localizare eveniment, nume si prenume conducători auto, martori, proprietari sau deținători auto, număr
                telefon mobil proprietar / conducător auto, martori, adresa de e-mail, adresă domiciliu si/sau
                reședința, fotografii ale autovehiculelor si/sau bunurilor implicate in eveniment (inclusiv ale daunei),
                sau alte fotografii &icirc;ncărcate de utilizatori, seria si numărul CI/BI / pașaport / card diplomatic,
                data expirarii CI/BI / pasaport / card diplomatic, numărul permisului de conducere, categorie, data
                expirării / certificatul de &icirc;nmatriculare a vehiculului, Serie caroserie și număr
                &icirc;nmatriculare auto, Serie și număr poliță asigurare RCA, perioada valabilitate, asigurator și
                emitent, Existență asigurare casco, Coordonate GPS, Semnături, cookies, adrese IP si alte informatii
                tehnice privind dispozitivele utilizate pentru accesarea contului de client si alte tipuri de date cu
                caracter personal retinute prin acte emise de autoritati publice din tara sau strainatate, ori prin
                declaratii ale unor persoane fizice ori juridice.
                <p></p>
                Datele Dvs. de identificare si contact vor putea fi utilizate pentru indeplinirea scopurilor de mai jos,
                atat
                prin raportare la raporturile juridice asociate rolului de persoana fizica, cat si rolului de
                reprezentant/persoana de contact/utilizator al aplicației AMIABILA entitate juridica. De asemenea am
                putea
                prelucra date referitoare la interactiunile dvs. cu BAAR, Asiguratorii RCA si AMIABILA pe retelele de
                socializare, cum ar fi Facebook, Twitter, Instagram, Google+ si YouTube etc. Urmarim mesajele publice,
                postarile, aprecierile si raspunsurile la si despre Operatori si AMIABILA pe internet. Totodata, mai
                prelucram informatii referitoare la activitati frauduloase sau potential frauduloase, precum date
                referitoare la acuzatii sau condamnari legate de infractiuni precum frauda, spalare de bani sau
                finantarea
                actelor de terorism pentru prevenirea unor practici frauduloase sau care contravin cerintelor si
                sanctiunilor internationale sau pentru indeplinirea cerintelor aplicabile in materia prevenirii spalarii
                de
                bani, a finantarii activitatilor de terorism sau pentru prevenirea evaziunii fiscale.
            </li>
        </ol>
        <p></p>
        <ol start="5">
            <li><strong>Utilizarea datelor cu caracter personal si temeiul prelucrarii: </strong>Prelucrarea se refera
                la toate operatiunile care pot fi efectuate in legatura cu datele cu caracter personal, cum ar fi
                colectarea, inregistrarea, stocarea, ajustarea, organizarea, utilizarea, divulgarea, transferul,
                arhivarea sau stergerea acestora in conformitate cu legile aplicabile. Nu folosim datele dvs. personale
                decat in baza temeiurilor legale de prelucrare prevazute de legislatia aplicabila in materia protectiei
                datelor, respectiv:
                <ol type="a">
                    <li><u>Pe baza consimtamantului Dvs.</u>, puteti receptiona alerte cu privire la expirarea
                        valabilității poliței de asigurare RCA.</li>
                    <li><u>Executarea contractelor la care sunteti parte</u> sau realizarea de demersuri inainte de
                        incheierea contractelor: Utilizam informatii despre dvs., atunci cand incheiati un contract cu
                        noi (indiferent de calitatea pe care o aveti intr-un astfel de contract) pentru a furniza
                        serviciile solicitate, indiferent de canal sau cand trebuie sa va contactam (pentru a va
                        notifica cu privire la modificarile aplicabile termenilor si conditiilor contractelor dvs.,
                        expirarea unui termen, -<u>Prelucrarea este necesara in vederea indeplinirii unei obligatii
                            legale care revine Asiguratorilor RCA și/sau BAAR &icirc;n baza art. 6 alin. (1) lit. c) din
                            Regulament si a art. 41 alin. (6) din Norma Autoritatii de Supraveghere Financiara nr.
                            20/2017 privind asigurările auto din Rom&acirc;nia, ca urmare a optiunii conducatorilor auto
                            implicati in accident de a folosi si a completa formularul de &bdquo;Constatare amiabilă de
                            accident&rdquo; &icirc;n format electronic</u>. Astfel, pentru evenimentele &icirc;n care au
                        fost implicate două vehicule asigurate, din care au rezultat numai prejudicii materiale,
                        avizarea daunei se poate face si in baza formularului &bdquo;Constatare amiabilă de
                        accident&rdquo;, ce va sta la baza, alaturi de alte acte, inscrisuri si/sau proceduri,
                        stabilirii daunei si executarii de catre Asiguratorul(ii) RCA a contractului/contractelor de
                        asigurare auto obligatorie la care una sau mai multe dintre persoanele implicate in accident
                        sunt parte: Utilizam informatii despre dvs. atunci cand aveti calitatea de utilizator al
                        AMIABILA sau cand datele dvs. au fost inregistrare și.sau incarcate in AMIABILA cu privire la
                        sau in legatura cu un accident pentru a realiza demersurile necesare in vederea constatarii
                        amiabile a accidentului si a circumstantelor in care acesta s-a produs sau cand trebuie sa va
                        contactam (pentru a va notifica cu privire la modificarile aplicabile Termenilor si Conditiilor
                        AMIABILA, expirarea unui termen etc, sau pentru a va furniza informatii despre serviciile pe
                        care le utilizati). In mod similar, datele dvs. cu caracter personal sunt supuse prelucrarii
                        pentru a putea sa dam curs solicitarilor dvs. precum furnizarea de informatii suplimentare,
                        actualizarea de date, investigarea si remedierea unor disfunctionalitati ce va afecteaza
                        serviciile utilizate, solutionarea de cereri, exercitarea de catre dvs. a drepturilor ce va
                        revin in calitate de subiect al prelucrarii de date cu caracter personal, reclamatii sau petitii
                        (in unele cazuri, solutionarea unei cereri poate fi efectuata de catre Asiguratorul(ii) RCA sau
                        BAAR ca si operator de date cu caracter personal in vederea indeplinirii obligatiilor legale ce
                        ii revin fiecareia dintre acestia).</li>
                    <li><u>Prelucrarea este necesara in vederea indeplinirii unei obligatii legale care revine
                            Asiguratorilor RCA și/sau BAAR &icirc;n baza art. 6 alin. (1) lit. c) din Regulament.</u>
                        Anumite categorii de date cu caracter personal sunt prelucrate pentru a indeplini obligatiile
                        BAAR si/sau Asiguratorilor RCA stabilite prin Legea nr. 132/2017 si Normele emise in aplicarea
                        acesteia de catre Autoritatea de Supraveghere Financiara.</li>
                    <li><u>Prelucrarea este necesara in scopul intereselor legitime urmarite de Asiguratorii RCA si/sau
                            BAAR in baza art. 6 alin. (1) lit. f) din Regulament</u><u>, in ceea ce priveste
                        </u><u>administrarea, </u><u>imbunatatirea si dezvoltarea </u><u>aplicatiei AMIABILA</u>. Putem
                        analiza modul in care utilizati si interactionati cu serviciile/aplicatiile noastre pentru a
                        intelege mai multe despre dvs. si pentru a afla unde si cum ne putem imbunatati. Totodata,
                        utilizam informatiile in scopul prevenirii fraudelor si furtului de identitate, pentru a ne
                        asigura ca datele dvs. cu caracter personal nu sunt accesibile persoanelor neautorizate. Avem
                        interesul de a va proteja datele cu caracter personal si de a preveni, detecta si limita orice
                        incalcare a securității privind datele dvs. cu caracter personal. Acestea includ date pe care
                        suntem obligati sa le colectam despre dvs., de exemplu pentru a va verifica identitatea atunci
                        cand deveniti utilizati AMIABILA. In plus, nu dorim doar sa va protejam impotriva fraudei si a
                        criminalitatii informatice, ci avem, de asemenea, datoria de a asigura securitatea si
                        integritatea AMIABILA. Pentru a va proteja inclusiv impotriva activitatilor frauduloase online,
                        de exemplu, daca numele dvs. de utilizator si parola sunt compromise, putem utiliza anumite
                        informatii despre dvs. (de exemplu, numele, varsta, nationalitatea, adresa IP, IMEI telefon
                        etc.) in scopuri de creare de profiluri pentru a detecta activitatile frauduloase si autorii
                        acestora. Putem folosi datele dvs. cu caracter personal pentru a va avertiza daca detectam
                        activitati suspecte in contul dvs. AMIABILA, inclusiv accesari neobisnuite sau suspecte, ori
                        utilizari dintr-o locatie atipica. Totodata, putem colecta si pastra datele Dvs. pentru a
                        asigura probatoriul necesar pentru solutionarea unor potentiale dispute sau litigii. Interesele
                        legitime ale Asiguratorilor RCA respectiv BAAR mai vizeaza prevenirea fraudelor, conservarea
                        unor drepturi la aparare prin asigurarea dovezilor privind executarea obligatiilor, ori, dupa
                        caz, eficientizarea proceselor interne si cresterea vitezei de solutionare a daunelor ori de
                        indeplinire a obligatiilor legale;</li>
                </ol>
            </li>
            <li><strong>Categoriile de destinatari ai datelor Dvs.</strong><strong>cu caracter personal</strong> ar
                putea fi după caz, următoarele:
                <ol type="a">
                    <li><u>Autorități si institutii publice centrale si locale din Romania sau autoritati din alte
                            tari</u>;
                        instituții de reglementare si supraveghere a pietelor financiare, cum ar fi: Autoritatea de
                        Supraveghere
                        Financiara, Fondul de garantare a asiguratilor, alte autoritati publice care mentin evidenta
                        vehiculelor
                        inregistrate, Consiliul Concurentei, alte entități de supraveghere din sectorul financiar,
                        Autoritatea
                        Nationala de Administrare Fiscala, Oficiul National de Prevenire si Combatere a Spalarii
                        Banilor.</li>
                    <li><u>Autoritati judiciare/de investigatie</u>, cum ar fi: politia, Parchetul, instanțe si
                        organismele de
                        arbitraj/ mediere, la cererea expresa si legala a acestora.</li>
                    <li><u>Institutii de investigare si suport pentru incidente de securitate cibernetica</u>.</li>
                    <li><u>Furnizori de servicii</u>. In cazul in care folosim alti furnizori de servicii sau alte parti
                        terte
                        pentru a intreprinde anumite activitati in desfasurarea normala a activității cu privire la sau
                        in
                        legatura cu AMIABILA este posibil sa fim nevoiti sa le comunicam datele cu caracter personal ce
                        sunt
                        necesare pentru realizarea unei anumite sarcini. Furnizorii de servicii ne sprijina cu
                        activitati de
                        tipul: proiectarea, dezvoltarea si mentinerea instrumentelor si aplicatiilor bazate pe internet;
                        furnizorii de servicii IT care pot furniza aplicatii sau servicii de infrastructura (cum ar fi
                        servicii
                        de stocare de tip cloud sau analiza de date); Activitati privind gestionarea comunicatiilor cu
                        utilizatorii AMIABILA si clienti ai Asiguratorilor; campanii de promovare sau analiza de piata
                        cu
                        privire la sau in legatura cu AMIABILA; Intocmirea rapoartelor si statisticilor, tiparirea
                        materialelor
                        si conceperea serviciilor; Servicii juridice, de audit sau alte servicii speciale prestate de
                        avocati,
                        notari, administratori, auditori, executori, entitati specializate in recuperarea de creante,
                        evaluatori, sau alti consilieri profesioniști; Identificarea, investigarea sau prevenirea
                        fraudei sau a
                        altor abateri de catre companiilor specializate; Prestarea de servicii specializate, cum ar fi
                        cel posta
                        de catre agentii nostri, arhivarea inregistrarilor realizate in format fizic, contractori si
                        furnizori
                        externi de servicii, Efectuarea unor mecanisme de securizare (cum ar fi administratorii,
                        investitorii si
                        consilierii), Prestarea unor servicii externalizate de catre BAAR ș/sau Asiguratorii RCA.</li>
                    <li><u>Asiguratori, organisme si institutii care activeaza in cadrul sistemului Carte Verde sau cel
                            al
                            protectiei vizitatorilor</u>. Intre aceste entitati s-ar putea numara societati specializate
                        in
                        constatarea si evaluarea daunelor, organe abilitate sa cerceteze accidente de autovehicule,
                        Directia
                        regim permise de conducere si inmatriculare a vehiculelor, Directia pentru evidenta persoanelor
                        si
                        administrarea bazelor de date, traducatori, experti tehnici judiciari, asiguratorii si
                        reasiguratori din
                        tara ori strainatate stabiliti, dupa caz, in sau in afara Spatiului Economic European si
                        mandatari ai
                        acestora, Birouri Carte Verde stabilite in state ce fac parte din cadrul Spatiului Economic
                        European
                        sau, dupa caz, din afara acestuia, Organisme de Compensare si Fonduri de garantare omoloage,
                        persoane
                        fizice ori juridice care au suferit un prejudiciu si care justifica un interes legitim pentru
                        obtinerea
                        respectivelor informatii. Pentru situatiile in care Asiguratorii RCA și/sau BAAR exporta date cu
                        caracter personal catre organizatii internationale ori catre state din afara Spatiului Economic
                        European, la cerere va putea furniza dovezi privind garantiile adecvate implementate.</li>
                </ol>
            </li>
            <li><strong>Prelucrarea datelor cu caracter personal nu implica un proces decizional automatizat.</strong>
                Operatorii va informeaza ca dvs. nu veti face obiectul unei decizii individuale automatizate, inclusiv
                profilare cu privire la sau in legatura cu utilizarea aplicatiei AMIABILA.
            </li>
        </ol>
        <ol start="8">
            <li><strong>Datele personale vor fi pastrate pana la realizarea scopului și/sau &icirc;ndeplinirea
                    obligațiilor legale privind stocarea</strong> si atat timp cat Asiguratorii RCA sau BAAR justifica
                un interes legitim pentru pastrarea acestora. Fara a ne limita, regula in general aplicata vizeaza
                perioadele minime de stocare a dosarelor de dauna, a informatiilor privind politele de asigurare si
                istoricul de daune prevazuta prin legislatia specifica. Datele cu caracter personal vor putea fi
                pastrate pentru a conserva exercitarea unui drept pe cale judecatoreasca sau de a asigura dovezile
                necesare pentru indeplinirea obligatiilor legale.</li>
            <li><strong>Drepturi privind prelucrarea datelor cu caracter personal</strong>. Fara a afecta obligatiile
                legale ale Asiguratorilor RCA și/sau BAAR sau ale celor pe care aceste entități ii reprezintă puteti
                solicita accesul la datele cu caracter personal, rectificarea sau stergerea acestora sau restrictionarea
                prelucrarii, avand dreptul de a va opune prelucrarii, precum si dreptul la portabilitatea datelor dupa
                cum urmeaza:
                <ol type="a">
                    <li>Dreptul de acces la datele cu caracter personal - Va permite sa obtineti din partea noastra o
                        confirmare a faptului ca AMIABILA prelucreaza sau nu date personale care va privesc si ce astfel
                        de date sunt prelucrate. Pentru situatia in care datele cu caracter personal care va privesc nu
                        sunt colectate de la dvs., puteti obtine de la Operatori orice informatii disponibile privind
                        sursa acestora, in cazurile in care nu avem o interdictie legala privind divulgarea sursei.</li>
                    <li>Dreptul la rectificare - Presupune dreptul de a solicita rectificarea sau actualizarea datelor
                        dvs. cu caracter personal inexacte sau completarea acestora, atunci cand acestea sunt
                        incomplete.</li>
                    <li>Dreptul la stergerea datelor sau &bdquo;dreptul de a fi uitat&rdquo; - Presupune dreptul de a
                        solicita stergerea datelor dvs. cu caracter personal (DCP), in anumite circumstante in care
                        legea permite acest lucru, precum (i) DCP nu mai sunt necesare pentru indeplinirea scopurilor
                        pentru care au fost colectate sau prelucrate, (ii) datele dumneavoastra au fost prelucrate
                        ilegal, (iii) prelucrarea datelor a avut loc pe baza consimtamantului dvs., iar acesta a fost
                        retras.</li>
                    <li>Dreptul de a va retrage consimtamantul - Consimtamantul poate fi retras in orice moment, atunci
                        cand prelucrarea datelor dvs. cu caracter personal a fost efectuata pe baza consimtamantului
                        dumneavoastra.</li>
                    <li>Dreptul la restricționarea prelucrarii - Presupune dreptul de a solicita si de a obtine
                        restricționarea prelucrarii datelor dvs. cu caracter personal, in anumite circumstante, precum
                        (i) dvs. contestati exactitatea datelor, pe perioada ce ne-ar permite verificarea exactitatii
                        acelor date, (ii) datele dvs. au fost prelucrate ilegal, iar dvs. va opuneti stergerii acestora,
                        solicitand restrictionarea utilizarii lor.</li>
                    <li>Dreptul la portabilitatea datelor - Presupune dreptul de a primi datele dvs. cu caracter
                        personal si pe care ni le-ati furnizat, cu dreptul de a le transmite unui alt operator, in cazul
                        in care (i) prelucrarea datelor s-a efectuat, in baza consimtamantului dumneavoastra sau a
                        contractului incheiat cu noi, iar prelucrarea datelor cu caracter personal s-a efectuat prin
                        mijloace automate. Acest drept poate fi exercitat numai in masura in care prelucrarea datelor
                        furnizate a fost efectuata exclusiv prin mijloace automate si doar acolo unde extragerea acestor
                        date este fezabila din punct de vedere al capacitatilor tehnice folosite de Operator.</li>
                    <li>Dreptul la opozitie - Presupune ca, in orice moment, aveti dreptul de a va opune, din motive
                        legate de situatia particulara in care va aflati, prelucrarii datelor cu caracter personal in
                        anumite circumstante, precum (i) prelucrarea a fost efectuata in interesul nostru legitim sau
                        (ii) prelucrarea are drept scop marketingul direct, inclusiv crearea de profiluri pe baza
                        respectivelor dispozitii.</li>
                    <li>Dreptul de a nu face obiectul unei decizii bazate exclusiv pe prelucrarea automata a datelor
                        dumneavoastra, inclusiv crearea de profiluri care produc efecte juridice sau care va afecteaza
                        semnificativ presupune ca aveti dreptul de a solicita interventie umana din partea Operatorului,
                        de a va exprima punctul de vedere si de a contesta decizia.</li>
                    <li>Dreptul de a depune o plangere la Operatorii relevanti si/sau BAAR folosind datele de contact
                        indicate in partea de inceput a prezentei si/sau la Autoritatea Nationala de Supraveghere a
                        Prelucrarii Datelor cu Caracter Personal (ANSPDCP), in cazul in care considerati ca prelucrarea
                        datelor dvs. cu caracter personal incalca Regulamentul: .</li>
                    <li>Dreptul de a va adresa justitiei.</li>
                    <li>In situatia in care datele Dvs. cu caracter personal sunt prelucrate in baza consimtamantului
                        Dvs. exprimat direct ori in relatia cu alte entitati, aveti dreptul sa retrageti respectivul
                        consimtamant direct din meniurile AMIABILA.</li>
                </ol>
            </li>
            <li><strong>Masuri tehnice si organizatorice pentru protejarea datelor Dvs.</strong> Luam masuri tehnice si
                organizatorice adecvate (politici si proceduri, securitate IT etc.) pentru a asigura confidentialitatea
                si integritatea datelor dvs. cu caracter personal si a modului in care sunt prelucrate. Aplicam un cadru
                intern de politici si standarde minime la toate nivelurile organizatiei noastre pentru a pastra
                siguranta datelor dvs. Aceste politici si standarde sunt actualizate periodic pentru a corespunde
                reglementarilor si evolutiei pietei. In plus, angajatii Asiguratorilor RCA și ai BAAR au obligația de a
                păstra confidențialitatea si nu pot divulga datele dvs. cu caracter personal in mod ilegal sau inutil.
                Facem eforturi sa protejam datele dvs., dar este recomandat ca la randul dvs. să instalati aplicatii
                antivirus, anti spyware si firewall. Actualizati sistemele de operare ale dispozitivului in mod regulat.
                Nu lasati echipamentele nesupravegheate. Pastrati parolele strict confidentiale si folositi parole
                puternice.</li>
            <li><strong>Putem modifica aceasta Nota de informare</strong> pentru a respecta orice modificare a legii
                si/sau pentru a reflecta modul in care AMIABILA prelucreaza datele cu caracter personal. Ori de cate ori
                va fi adusa vreo modificare prezentei <strong>Note de informare</strong>, aceasta va fi actualizată si
                accesibila in cadrul aplicatiei AMIABILA.</li>
            <li>Prezenta <strong>Nota de informare </strong>priveste și are legatura strict cu prelucrarea datelor dvs.
                cu caracter personal in cadrul aplicatiei AMIABILA si nu afecteaza si nu inlocuieste, in niciun mod,
                orice alta Nota de informare sau document similar pe care dvs. l-ati primit sau ar fi trebuit sa-l
                primiti de la Asiguratori sau de la BAAR in legatura cu relatia stabilita intre acestia si dvs.,
                independent de aplicatia AMIABILA.</li>
        </ol>
    </div>
</div>        </div>

)

export default TermsAndConditions;
