import styles from './documentitem.module.css';
import {ReactComponent as DocumentIco} from '../../../../assets/images/svg/DocumentIco.svg';
import {t} from "i18next";
import {BASE_URL} from "../../../../helpers/api/base_url";

const DocumentItem = ({fileID}) => (
    <a style={{textDecoration: 'none'}} href={`${BASE_URL}/Accidents/GetAccidentFileByToken?FileID=${fileID}&Token=${sessionStorage.getItem('filesToken')}`}>
        <div className={styles['container']}>
            <DocumentIco className={styles['documentIco']}/>
            <h3 className={styles['title']}>{t('_.accidentStatementForm')}</h3>
        </div>
    </a>

)

export default DocumentItem;
