import {APPLE_STORE, PLAY_STORE} from "../../utils/storeLinks";
import {ReactComponent as GoogleStore} from "../../assets/images/svg/google-play-badge-logo-svgrepo-com.svg";
import {ReactComponent as AppStore} from "../../assets/images/svg/Download_on_the_App_Store_Badge.svg";
import styles from "./styles/storeButtons.module.css";

export const PlayStoreButton = ({style = {}}) => (
    <a href={PLAY_STORE}><GoogleStore className={styles['branding-store-button']} style={style}/></a>
)

export const AppStoreButton = ({style = {}}) => (
    <a href={APPLE_STORE}><AppStore className={styles['branding-store-button']} style={style}/></a>
)
