import BasePage from "../base-page/BasePage";
import styles from './styles/deleteuser.module.css';
import {useEffect, useState} from "react";
import StatusDiv from "./components/DeleteUserForm/StatusDiv";
import DeleteUserForm from "./components/DeleteUserForm/DeleteUserForm";
import DeleteUserConfirmForm from "./components/DeleteUserForm/DeleteUserConfirmForm";
import {t} from "i18next";
import {useSearchParams} from "react-router-dom";
import {setSessionTimeout} from "./helpers/setSessionTimeout";



const DeleteUser = () => {
    const [error, setError] = useState();
    const [mode, setMode] = useState("form"); //form or list
    const [searchParams, setSearchParams] = useSearchParams();
    const [sessionTimerCount, setSessionTimerCount] = useState();

    const getEmailToken = () => {
        const token = searchParams.get("token");
        if (token) {
            sessionStorage.setItem("deleteUserToken", token);
            searchParams.delete("token");
            setSearchParams(searchParams);
        }
    }

    useEffect(() => {
        const getToken = async () => {
            try {
                getEmailToken();
                const deleteUserToken = sessionStorage.getItem("deleteUserToken");
                if (deleteUserToken) {
                    setSessionTimeout(setSessionTimerCount, setMode);
                    setMode('list');
                }
            } catch (e) {
                setError(e);
            }
        }
        void getToken();
    }, []);

    const revokeSession = () => {
        sessionStorage.removeItem('deleteUserToken');
        setMode("form");
    }


    return (
        <BasePage>
            <div className={styles['container']}>
                <div>
                    <h1>{t('deleteUser.title')}</h1>
                    <p>{t('deleteUser.description')}</p>
                </div>
                {error &&
                    <div style={{marginTop: '1.5rem'}}>
                        <StatusDiv dismiss={() => setError()}
                                   statusObject={{
                                       icon: 'report',
                                       rectangleColor: 'red',
                                       text: error.message,
                                   }}/>
                    </div>
                }

                {mode === 'list' ?
                    <>
                    <h4  style={{marginBottom:20}}>{t('deleteUser.confirmDeleteCaption')}</h4>
                    <DeleteUserConfirmForm setError={setError}/>
                    <h4 onClick={revokeSession}
                        style={{color: 'rgb(66, 129, 225)', cursor: 'pointer', marginTop:20}}>{t('_.closeSession')}</h4>
                    </>
                    :
                    <DeleteUserForm setError={setError}
                    />
                }
                
            </div>
        </BasePage>
    )
}

export default DeleteUser;
