import Navlinks from "./Navlinks";
import styles from './styles/navlinks.module.css';
import {useState} from "react";
import ChooseLanguage from "../ChooseLanguage/ChooseLanguage";
import Links from "./Links";
import {footerLinks, links} from "./dict/links";

const HeaderNavlinks = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false);

    return (
        <>
            <i onClick={() => setIsNavExpanded(!isNavExpanded)}
               className={`material-icons ${styles['hamburger']}`}>menu</i>
            <div
                className={isNavExpanded ? `${styles["navigation-menu"]} ${styles['expanded']}` : styles["navigation-menu"]}>
                <ul>
                    <Links links={footerLinks} linkClass={styles['link']}/>
                    <ChooseLanguage/>
                </ul>
            </div>
            <Navlinks links={links} containerClass={styles['menu-container']} linkClass={styles['link']}/>
        </>
    )

}

export default HeaderNavlinks;
