import styles from './styles/landingPage.module.css';
import {ReactComponent as AmiabilaWhiteLogo} from '../../assets/images/svg/AmiabilaWhiteLogo.svg';
import Button from "../../components/button/Button";
import { useNavigate } from "react-router-dom";
import {t} from "i18next";
import ChooseLanguage from "../../components/ChooseLanguage/ChooseLanguage";

const LandingPage = () => {
    const navigate = useNavigate();

    return (
        <div className={styles['full-screen']}>
            <div className={styles['content']}>
                <div className={styles['header']}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <AmiabilaWhiteLogo className={styles['logo']}/>
                        <ChooseLanguage style={{color: 'white'}}/>
                    </div>
                </div>
                <div className={styles['footer']}>
                    <div className={styles['text-container']}>
                        <h1 className={styles['title']}>{t('landing.title')}</h1>
                        <h3 className={styles['description']}>{t('landing.description_1')} <b>{t('landing.description_2')}</b></h3>
                    </div>
                   <Button onPress={() => navigate('/home')} className={styles['landing-pg-btn']} color={'rgba(255,255,255,0.13)'}>
                       <p className={styles['landing-pg-btn-txt']}>{t('_.more')}</p>
                       <i style={{color: 'white', fontSize: '3em'}} className="material-icons">east</i>
                   </Button>
                </div>
            </div>
        </div>
    )
}

export default LandingPage;
